import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'gatsby';
import {
  desktop, mobileM, mobileS, tablet,
} from '../../responsive';
import ProvImg from '../../images/provider.webp';

export const ProvContainer = styled.div`
  /* padding: 54px 24px;
  box-sizing: border-box;
  color: rgb(0, 35, 75);
  font-family: oswald;
  //background-color: rgb(224, 245, 231);
  ${mobileS({ padding: '20px' })}
  ${mobileM({ padding: '20px' })} */
  // background-image: linear-gradient(
  //     to bottom,
  //     rgba(245, 246, 252, 0.52),
  //     rgb(241 255 245 / 0.52%)
  //   ),
  //   url(${ProvImg});
  width: 100%;
  height: 400px;
  // background-size: cover;
  // background-repeat: no-repeat;
  ${mobileS({
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: "left",
    height: 'auto',

  })}
`;

export const ProvWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 320px) {
    margin: 3em 0.5em;
  }
`;

export const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 38%;
  background: #2e4158;
  //background: rgb(72 67 67 / 20%);
  //backdrop-filter: blur(2px);
  border-radius: 30px;
  align-items: center;
  ${mobileS({ width: '100%', padding: '1em' })}
  ${tablet({ width: '60%' })}
  ${desktop({ width: '45%' })}
`;
export const Line = styled.hr`
  //background-color: #ffffff;
  height: 1.5px;
  width: 50%;
  margin: 0.5em;
  color:white;
`;
export const Header = styled.p`
  font-size: 2.5em;
  color: #ffffff;
  font-family: Oswald;
  font-weight: 500px;
  margin: 0;
  margin-top: 10px;
  ${mobileS({ lineHeight: '1em', textAlign: 'center', margin: '10px' })}
`;
export const BenefitHeader = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 70px 0 35px 0;
  @media screen and (min-width: 320px) {
    width: 100%;
  }
`;
export const Desc = styled.h3`
  font-size: 1.3em;
  color: #ffffff;
  font-family: Oswald;
  margin: 0;
  font-weight: 300px;
  margin-top: 10px;
  ${mobileS({ lineHeight: '1.5em', textAlign: 'center', fontSize: '1.5em' })}
`;
export const DescUl = styled.ul`
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  ${mobileS({
    display: 'flex',
    flexDirection: 'column',
    listStyleType: 'none',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '0.2em',
  })}
`;
export const DetailList = styled.li`
  font-size: 1.3em;
  line-height: 1.3em;
  color: #ffffff;
  font-weight: 300;
  font-family: Oswald;
  margin: 0;
  margin-top: 15px;
`;
export const Heading = styled.h1`
  margin-top: 100px;
  font-family: Oswald;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 400;
  color: ${({ lightText }) => (lightText ? '#1D9682' : '#f2e3e3')};
`;
export const ProvBtnLink2 = styled.button`
  background: #1d9682;
  white-space: nowrap;
  color: white;
  font-size: 20px;
  font-family: Oswald;
  font-weight: 300;
  outline: none;
  cursor: pointer;
  padding: 14px 90px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: "center";
  /* border: 3px solid #60c198; */
  box-sizing: border-box;
  align-self: center;
  filter: drop-shadow(2px 4px 25px #d9f3e8);
  border-radius: 10px;
  ${mobileS({
    margin: '10px',
    width: '90%',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  })}
  ${mobileM({ padding: '10px 40px', margin: '10px' })}
  ${tablet({ width: '60%' })}
  ${desktop({ width: '60%' })}
  

  &:hover {
    transition: all 0.2sease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
`;
export const ModalCont = styled.div`
  font-family: oswald;
  height: 500px;
`;
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const sharedStyles = css`
  background-color: #eee;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
`;
export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const StyledIcon = styled(AiOutlineClose)`
  cursor: pointer;
  margin-bottom: 20px;
  color: #1d9682;
  align-self: flex-end;
  font-size: 20px;
`;
export const SuccessImg = styled.img`
  width: 70%;
`;
export const SuccessHeader = styled.span`
  font-size: 1.5em;
  margin-top: 10px;
  font-weight: 300;
  color: #1d9682;
`;
export const SuccessSubtitle = styled.span`
  font-size: 1.5em;
`;
export const SuccessText = styled.p`
  font-size: 1.5em;
  margin: 10px 0 0;
`;

export const StyledForm = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  font-family: oswald;
  display: flex;
  flex-direction: column;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ModalImg = styled.div`
  width: 60%;
  align-self: center;
  margin-bottom: 10px;
`;
export const ModalHeader = styled.h2`
  font-family: oswald;
  line-height: 40px;
  font-weight: 300;
  color: #1d9682;
`;
export const Subtitle = styled.h4`
  margin-bottom: 20px;
  color: gray;
  font-weight: 200;
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  ${sharedStyles}
`;

export const StyledTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 100px;
  resize: none;
  ${sharedStyles}
`;
export const StyledButton = styled(motion.button)`
  display: block;
  background: #1d9682;
  color: #fff;
  font-size: 0.9rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
  align-self: flex-start;
`;

export const StyledFieldset = styled.fieldset`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0;

  legend {
    padding: 0 10px;
  }

  label {
    padding-right: 20px;
  }

  input {
    margin-right: 10px;
  }
`;

export const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
`;
export const ProvBtnLink = styled.button`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  /* box-shadow: 2px 4px 25px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  white-space: nowrap;
  background: #1d9682;
  color: #ffffff;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 14px 40px;
  margin: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";
  font-family: oswald;

  &:hover {
    transition: all 0.2sease-in-out;
    color: #1d9682;
    background: #ffffff;
  }
  @media screen and (min-width: 320px) {
    font-size: 1.2em;
    margin: 1em 0;
  }
`;
export const ReadMoreBtn = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  width: 130px;
  font-family: oswald;
  text-align: center;
  margin: 10px 20px;
  color: #1d9682;
  border-radius: 8px;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
  }
  @media screen and (min-width: 320px) {
    margin: 0;
  }
`;
