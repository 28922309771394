import Icon from './ProvIcon.svg';

export const servDetails = [
  {
    id: 0,
    title: 'Medicine',
    link: '/pharmacy',
    icon: `${Icon}`,
    subtitle:
      '  Order medication and healthcare products online from a wide selection of providers',
  },
  {
    id: 1,
    title: 'Doctor Consultation',
    link: '/doctors',
    icon: `${Icon}`,
    subtitle:
      '  Access virtual and in-person consultation from a wide selection of healthcare providers',
  },
  {
    id: 2,
    icon: `${Icon}`,
    link: '/medication-therapy-management',
    title: 'Medication Therapy Management',
    subtitle: ' Getting you the most out of your medication and treatment',
  },
  {
    id: 3,
    title: 'Remote Patient Monitoring',
    link: '/remote-patient-monitoring',
    icon: `${Icon}`,
    subtitle:
      ' Preventing costly healthcare complications by providing continuous care',
  },
  {
    id: 4,
    title: 'Health tracking',
    link: '/userprofile',
    icon: `${Icon}`,
    subtitle:
      ' Take charge of your health—easily track your symptoms, vitals, sugars, and pressure',
  },
];
export const provDetails = [
  {
    id: 0,
    title: 'Sales',
    icon: `${Icon}`,
    subtitle:
      'Get online orders from patients within the platform to increase your revenue ',
  },
  {
    id: 1,
    icon: `${Icon}`,
    title: 'Customer experience',
    subtitle:
      'Improve your customers’ experience and eliminate friction in the buying process',
  },
  {
    id: 2,
    title: 'Data Driven Decisions',
    icon: `${Icon}`,
    subtitle:
      'Automated reports and data analytics for better decision making in marketing, customer satisfaction, inventory management, medication purchase',
  },
  {
    id: 3,
    title: 'Pharmacy Management System',
    icon: `${Icon}`,
    subtitle:
      'Enabling automation of pharmacy operations, medication therapy management, remote patient monitoring, patient engagement',
  },
  {
    id: 4,
    title: 'Generate Demand',
    icon: `${Icon}`,
    subtitle:
      'Advertise to patients on the platform, reducing time to market and saving money and time.',
  },
  {
    id: 5,
    title: 'Expand Your Reach',
    icon: `${Icon}`,
    subtitle:
      'Reach more patients by marketing through health education and using an omnichannel approach',
  },
];
export const MtmProvBenefits = [
  {
    id: 0,
    title: 'Clients',
    icon: `${Icon}`,
    subtitle:
      'Get patients within the platform who need medication therapy management services to increase your revenue',
  },
  {
    id: 1,
    icon: `${Icon}`,
    title: 'Customer experience',
    subtitle:
      'Improve your customers’ experience and eliminate friction in the provision of Medication therapy management and other pharmaceutical services',
  },
  {
    id: 2,
    title: 'Data Driven Decisions',
    icon: `${Icon}`,
    subtitle:
      'Automated reports and data analytics for better decision making in marketing, patient satisfaction, medication therapy management, disease management',
  },
  {
    id: 3,
    title: 'Pharmacy Management System',
    icon: `${Icon}`,
    subtitle:
      'Enabling automation of pharmacy operations, medication therapy management, remote patient monitoring, clinicals and patient engagement',
  },

  {
    id: 4,
    title: 'Expand Your Reach',
    icon: `${Icon}`,
    subtitle:
      'Reach more patients by marketing through health education and using an omnichannel approach within the platform',
  },
];

export const provDetailsClinic = [
  {
    id: 0,
    title: 'Sales',
    icon: `${Icon}`,
    subtitle:
      'Get online patients within the platform to increase your revenue',
  },
  {
    id: 1,
    icon: `${Icon}`,
    title: 'Customer experience',
    subtitle:
      'Improve your customers’ experience and eliminate friction in bookings for appointments',
  },
  {
    id: 2,
    title: 'Data Driven Decisions',
    icon: `${Icon}`,
    subtitle:
      'Automated reports and data analytics for better decision making in marketing, customer satisfaction, inventory management, medication purchase',
  },
  {
    id: 3,
    title: 'Telemedicine',
    icon: `${Icon}`,
    subtitle:
      'Enabling telemedicine, remote patient monitoring, clinical charting and patient engagement',
  },
  {
    id: 4,
    title: 'Generate Demand',
    icon: `${Icon}`,
    subtitle:
      'Advertise to patients on the platform, reducing time to market and saving money and time.',
  },
  {
    id: 5,
    title: 'Expand Your Reach',
    icon: `${Icon}`,
    subtitle:
      'Reach more patients by marketing through health education and using an omnichannel approach',
  },
];
